import { IB2bButtonProps } from "./B2bButton.interface";

const B2bButton = (props: IB2bButtonProps) => {

    const { skus, title } = props;
    const b2bLink = `http://109.95.119.180/katalog/index.php?cat&wys=ka&szuk=${skus}`;

    return (
        <div className="align-self-center px-1 my-1">
            <a className="btn btn-warning shadow" href={b2bLink}>{title}</a>
        </div>
    );
}

export default B2bButton;