import { useEffect, useState } from "react";
import { IEvent, IOffer, IEventList, IOfferList } from "interfaces";
import { notificationService } from 'services';
import axios from 'axios';
import { axiosHelper } from 'helpers';
import OfferList from "./subcomponents/OfferList";

const OffersContainer = () => {

    const [items, setItems] = useState<IOffer[] | null>(null);
    const [events, setEvents] = useState<IEvent[] | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [reload, setReload] = useState<boolean>(false);

    const loadData = () => {
        setLoading(true);
        try {
            handleFetchApi();
            handleFetchApiEvents();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => loadData(), [reload]);

    const reloadOffers = () => {
        setReload(!reload);
    }

    const handleFetchApi = async () => {

        axios
            .get<IOfferList>(
                axiosHelper.buildApiUrl('offers'),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setItems(response.data.offers);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Oferta nie istnieje";
                notificationService.error(errorMessage);
                setItems(null);
                setError(error);
            });
    }

    const handleFetchApiEvents = async () => {

        axios
            .get<IEventList>(
                axiosHelper.buildApiUrl('events'),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setEvents(response.data.events);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Wydarzenie nie istnieje";
                notificationService.error(errorMessage);
                setEvents(null);
                setError(error);
            });
    }

    return (
        <OfferList items={items} events={events} loading={loading} error={error} reloadOffers={reloadOffers} />
    );
}

export default OffersContainer;