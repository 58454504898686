import { B2bButton } from "components";
import { FetchError, Loading, NoResults } from "components/shared";
import { ProductItem } from "./ProductItem";
import { IProductsListProps } from "./ProductsList.interface";

export const ProductsList = (props: IProductsListProps) => {

    const { items, skus, error, loading } = props;

    if (error) return <FetchError error={error} />;
    if (loading || !items) return <Loading />;
    if (items.length === 0) return <NoResults />;

    const components = items.map(item => <ProductItem key={item.sku} item={item} />);

    return (
        <>
            <div className="row mb-5">
                {components ? components : null}
            </div>
            {skus && <div className="position-fixed bottom-0 end-0 m-2 showroom-page-wrapper">
                <div className="showroom-page-b2b-button-panel">
                    <B2bButton skus={skus} title="Otwórz wszystkie produkty w B2B" />
                </div>
            </div>}
        </>
    );
}