import axios from 'axios';
import { axiosHelper, offerUrlHelper } from 'helpers';
import { IOfferItemProps } from './OfferItem.interface';
import { notificationService } from 'services';

const OfferItem = (props: IOfferItemProps) => {

    const { offer, events, reloadOffers } = props;

    const offerUrl = offerUrlHelper.getUrl(offer);

    const currentEvent = events?.find(event => event.id === offer.eventId);

    const handleDeleteClick = (clickEvent: React.MouseEvent<HTMLElement>) => {
        clickEvent.preventDefault();
        if (window.confirm("Na pewno usunąć ofertę?")) {
            axios.delete(axiosHelper.buildApiUrl(`offers/${offer.id}`))
                .then(response => {
                    if (response.status === 204) {
                        notificationService.success("Oferta została usunięta!");
                        reloadOffers();
                    }
                })
                .catch(error => {
                    console.error(error.response);
                    notificationService.error("Usuwanie oferty zakończone niepowodzeniem");
                });
        }
    }

    return (
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
            <div className="card shadow mb-4">

                <div className="card-header fw-bold">
                    <div className="py-2">
                        {offer.name}
                    </div>
                </div>

                <div className="card-body">
                    {currentEvent && <div className="py-2">
                        <p className="text-muted pb-0 mb-0">Wydarzenie:</p>
                        <p>{currentEvent?.name}</p>
                    </div>}
                    <div className="pb-2">
                        <p className="text-muted pb-0 mb-0">Kody własne:</p>
                        <p className="lh-sm">{offer.skus.replace(/,/g, ', ')}</p>
                    </div>
                    <div className="pb-2 mb-4">
                        <a href={offerUrl}>{offerUrl}</a>
                    </div>
                    <div className="pb-2">
                        <div className="btn-group" role="group">
                            <a href={`/offers/${offer.id}`} className="btn btn-outline-warning">
                                <i className="bi bi-pencil-fill me-1"></i>Edytuj
                            </a>
                            <button onClick={handleDeleteClick} className="btn btn-outline-danger">
                                <i className="bi bi-trash-fill me-1"></i>Usuń
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-footer">
                    {offer.date && <div className="text-muted py-1">
                        <small>Utworzona: {offer.date?.toLocaleString().replace('T', ' ').substring(0, 19)}</small>
                    </div>}
                    <div className="text-muted pb-1">
                        <small>Id: {offer.id}</small>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default OfferItem;