import { IEvent } from "interfaces";
import { useEffect, useState } from "react";
import { IEventFormContainerProps } from "./EventFormContainer.interface";
import EventFormItem from "./subcomponents/EventFormItem";
import axios from 'axios';
import { axiosHelper } from 'helpers';
import { notificationService } from 'services';

const EventFormContainer = (props: IEventFormContainerProps) => {

    const { id } = props;

    const [item, setItem] = useState<IEvent | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => loadData(), []);

    const loadData = () => {
        if (id !== null) {
            setLoading(true);
            try {
                handleFetchApi();
            } catch (error) {
                setError(error);
            }
        }
    };

    const handleFetchApi = async () => {

        axios
            .get<IEvent>(
                axiosHelper.buildApiUrl(`events/${id}`),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setItem(response.data);
                setLoading(false);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Wydarzenie nie istnieje";
                notificationService.error(errorMessage);
                setItem(null);
                setError(error);
            });
    }

    return (
        <EventFormItem item={item} loading={id !== null ? loading : false} error={error} />
    );
}

export default EventFormContainer;