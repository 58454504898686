import { useEffect, useState } from "react";
import { IPagination, IProduct, IProductList } from 'interfaces';
import { IProductsContainerProps } from "./ProductsContainer.interface";
import { notificationService } from 'services';
import { ProductsList } from "./subcomponents/ProductsList";
import axios from 'axios';
import { axiosHelper } from 'helpers';
import { Pagination } from "components/shared";

const ProductsContainer = (props: IProductsContainerProps) => {

    const { offer, paginationPage, handlePaginationPageChange } = props;

    const [items, setItems] = useState<IProduct[] | null>([]);
    const [skus, setSkus] = useState<string | null>(null);
    const [pagination, setPagination] = useState<IPagination | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const loadData = () => {
        setLoading(true);
        try {
            handleFetchApi();
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        loadData();
        return () => {
            setItems(null);
            setSkus(null);
            setPagination(null);
            setError(null);
            setLoading(false);
        }
    }, [offer, paginationPage]);

    const handleFetchApi = async () => {

        axios
            .get<IProductList>(
                axiosHelper.buildApiUrl('products'),
                axiosHelper.buildHeaders({ params: { 'offer': offer, 'page': paginationPage } }))
            .then(response => {
                setItems(response.data.products);
                setSkus(response.data.skus);
                setPagination(response.data.pagination);
                setLoading(false);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Showroom nie istnieje";
                notificationService.error(errorMessage);
                setItems(null);
                setSkus(null);
                setPagination(null);
                setError(error);
            });
    }

    return (
        <>
            <ProductsList
                items={items}
                skus={skus}
                loading={loading}
                error={error} />
            {items && <Pagination
                pagination={pagination}
                page={paginationPage}
                handlePaginationPageChange={handlePaginationPageChange} />}
        </>
    );
}

export default ProductsContainer;