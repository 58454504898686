import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from 'components/page';
import { EventsPage, EventFormPage, NotFoundPage, OfferFormPage, OffersPage, ShowroomPage } from 'pages';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <>
          <Routes>
            <Route path="events/:id" element={<EventFormPage />} />
            <Route path="events" element={<EventsPage />} />
            <Route path="offers/:id" element={<OfferFormPage />} />
            <Route path="offers" element={<OffersPage />} />
            <Route path="showroom" element={<ShowroomPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <ToastContainer autoClose={3000} limit={6} />
        </>
      </Layout>
    </BrowserRouter >
  );
}

export default App;