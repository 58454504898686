import { IEventItemProps } from "./EventItem.interface";
import axios from 'axios';
import { axiosHelper } from 'helpers';
import { notificationService } from "services";

const EventItem = (props: IEventItemProps) => {

    const { event, reloadEvents } = props;

    const handleDeleteClick = (clickEvent: React.MouseEvent<HTMLElement>) => {
        clickEvent.preventDefault();

        if (confirm("Na pewno usunąć wydarzenie?")) {
            axios.delete(axiosHelper.buildApiUrl(`events/${event.id}`))
                .then(response => {
                    if (response.status === 204) {
                        notificationService.success("Wydarzenie zostało usunięte!");
                        reloadEvents();
                    }
                })
                .catch(error => {
                    const apiErrorMessage = error.response.data.errorMessage;
                    let userErrorMessage = "Usuwanie wydarzenia zakończone niepowodzeniem";
                    if (apiErrorMessage.toLowerCase().includes("event containing offers cannot be deleted")) userErrorMessage += ", wydarzenie zawiera oferty";
                    console.error(apiErrorMessage);
                    notificationService.error(userErrorMessage);
                });
        }
    }

    return (
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className="card shadow mb-4">

                <div className="card-header fw-bold">
                    <div className="fw-bold py-2">{event.name}</div>
                </div>

                <div className="card-body">
                    <div className="btn-group" role="group">
                        <a href={`/events/${event.id}`} className="btn btn-outline-warning">
                            <i className="bi bi-pencil-fill me-1"></i>Edytuj
                        </a>
                        <button onClick={handleDeleteClick} className="btn btn-outline-danger">
                            <i className="bi bi-trash-fill me-1"></i>Usuń
                        </button>
                    </div>
                </div>

                <div className="card-footer">
                    {event.date && <div className="text-muted py-1">
                        <small>Utworzony: {event.date?.toLocaleString().replace('T', ' ').substring(0, 19)}</small>
                    </div>}
                    <div className="text-muted pb-1">
                        <small>Id: {event.id}</small>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default EventItem;