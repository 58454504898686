import { useEffect, useState } from "react";
import { IEvent, IEventList } from "interfaces";
import { notificationService } from 'services';
import axios from 'axios';
import { axiosHelper } from 'helpers';
import EventList from "./subcomponents/EventList";

const EventsContainer = () => {

    const [items, setItems] = useState<IEvent[] | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [reload, setReload] = useState<boolean>(false);

    const loadData = () => {
        setLoading(true);
        try {
            handleFetchApi();
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => loadData(), [reload]);

    const reloadEvents = () => {
        setReload(!reload);
    }

    const handleFetchApi = async () => {

        axios
            .get<IEventList>(
                axiosHelper.buildApiUrl('events'),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setItems(response.data.events);
                setLoading(false);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Wydarzenie nie istnieje";
                notificationService.error(errorMessage);
                setItems(null);
                setError(error);
            });
    }

    return (
        <EventList items={items} loading={loading} error={error} reloadEvents={reloadEvents} />
    );
}

export default EventsContainer;