import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NotificationService {

    success = async (message: string) => {
        toast.success(message, {
            position: 'bottom-right',
            autoClose: 2000,
            transition: Slide,
        });
    }

    error = async (message: string) => {
        toast.error(message, {
            position: 'bottom-right',
            theme: "colored",
            autoClose: 4000,
            transition: Slide,
        });
    }
}

const notificationService = new NotificationService();
export default notificationService;