import { Footer, Header } from 'components/page';
import { ILayoutProps } from './Layout.interface';
import './Layout.css';

const Layout = (props: ILayoutProps) => {
    return (
        <>
            <Header />
            <main className="container-fluid min-vh-100 layout-content">
                {props.children}
            </main>
            <Footer />
        </>
    );
}

export default Layout;
