import { OffersContainer } from 'containers';

const OffersPage = () => {

    return (
        <>
            <div className="row mb-3">
                <h3>Oferty</h3>
                <div className="m-2">
                    <a className="btn btn-outline-success" href="/offers/new">
                        <i className="bi bi-plus-circle-fill me-1"></i>Dodaj
                    </a>
                </div>
            </div>
            <OffersContainer />
        </>
    );
};

export default OffersPage;