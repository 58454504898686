import axios from "axios";
import { Images } from "components";
import { axiosHelper } from "helpers";
import { IImageList } from "interfaces";
import { useEffect, useState } from "react";
import { notificationService } from "services";
import { IImagesContainerProps } from "./ImagesContainer.interface";

const ImagesContainer = (props: IImagesContainerProps) => {

    const { sku } = props.item;

    const [images, setImages] = useState<string[] | null>([]);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const loadData = () => {
        setLoading(true);
        try {
            handleFetchApi();
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => loadData(), [sku]);

    const handleFetchApi = async () => {

        axios
            .get<IImageList>(
                axiosHelper.buildApiUrl(`images/${sku}`),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setImages(response.data.images);
                setLoading(false);
            })
            .catch(error => {
                notificationService.error("Nie można pobrać zdjęć! Spróbuj ponownie później.");
                setImages(null);
                setError(error);
            });
    }

    return (
        <Images item={props.item} images={images} />
    );
}

export default ImagesContainer;