import { IFetchErrorProps } from "./FetchError.interfaces";
import './FetchError.css';

const FetchError = (props: IFetchErrorProps) => {

    console.error(props.error);

    let errorMessage = "Błąd pobierania danych, spróbuj ponownie później ...";
    if (props.error.response?.status === 400) errorMessage = "Showroom nie istnieje";

    return (
        <div className="text-center">
            <i className="bi bi-exclamation-triangle text-danger fetch-error-icon"></i>
            <h3 className="mt-2 text-secondary">{errorMessage}</h3>
        </div>
    );
}

export default FetchError;