import { IOffer } from "interfaces";

class OfferUrlHelper {

    getUrl = (offer: IOffer) => {

        const windowLocationHref = window.location.href.toLowerCase();

        if (windowLocationHref.includes("localhost:7184") || windowLocationHref.includes("127.0.0.1:7184")) {
            return `http://localhost:7184/showroom?offer=${offer.eventId}${offer.id}`;

        } else if (windowLocationHref.includes("test.fairsboards.intranet.ef")) {
            return `https://test.fairsboards.intranet.ef/showroom?offer=${offer.eventId}${offer.id}`;

        } else if (windowLocationHref.includes("fairsboards.intranet.ef")) {
            return `https://fairsboards.intranet.ef/showroom?offer=${offer.eventId}${offer.id}`;

        } else {
            return `https://fairsboards.eurofirany.com.pl/showroom?offer=${offer.eventId}${offer.id}`;
        }
    }
}

const offerUrlHelper = new OfferUrlHelper();
export default offerUrlHelper;
