import './Footer.css';

const Footer = () => {
    return (
        <footer className="mt-4">
            <div className="bg-dark text-white-50 p-2">
                <div className="d-flex flex-row justify-content-center">
                    <img className="align-self-center footer-app-logo shadow" src="/images/logo.png" alt="[logo]" />
                    <p className="m-0 ps-2">FairsBoards, Copyright &copy; Eurofirany 1991-{new Date().getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
