import { IOfferListProps } from "./OfferList.interface";
import { FetchError, Loading, NoResults } from "components/shared";
import OfferItem from "./OfferItem";

const OfferList = (props: IOfferListProps) => {

    const { items, events, error, loading, reloadOffers } = props;

    if (error) return <FetchError error={error} />;
    if (loading || !items) return <Loading />;
    if (items.length === 0) return <NoResults />;

    const eventsComponents = items?.map(item => <OfferItem offer={item} events={events} reloadOffers={reloadOffers} />);

    return (
        <div className="row">
            {eventsComponents}
        </div>
    );
}

export default OfferList;