import { syncBuiltinESMExports } from "module";
import { IPaginationProps } from "./Pagination.interface";

const Pagination = (props: IPaginationProps) => {

    const { pagination, page, handlePaginationPageChange } = props;

    const handleClick = (value: number) => {
        if (value > 0 && pagination && value <= pagination.totalPages) {
            handlePaginationPageChange(value);
            scrollToTop();
        }
    }

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo(1, 1)
        }, 500);
    }

    let startButtons = [];
    let paginationButtons = [];
    let endButtons = [];

    if (pagination) {

        const delta = 3;
        const start = Math.max(2, page - delta);
        const end = Math.min(page + delta, pagination.totalPages - 1);

        for (let i = start; i <= end; i++) {
            paginationButtons.push(
                <li key={i} className={page === i ? "page-item active" : "page-item"}>
                    <button className="page-link shadow" onClick={() => handleClick(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        startButtons.push(
            <li key={0} className={page === 1 ? "page-item disabled" : "page-item"}>
                <button className="page-link shadow" aria-label="Poprzednia" onClick={() => handleClick(page - 1)}>
                    <span aria-hidden="true">&laquo;</span>
                </button>
            </li >
        );

        startButtons.push(
            <li key={1} className={page === 1 ? "page-item active" : "page-item"}>
                <button className="page-link shadow" onClick={() => handleClick(1)}>1</button>
            </li >
        );

        if (start > 2) {
            startButtons.push(
                <li key={-1} className="page-item px-3">
                    <span className="text-primary">...</span>
                </li>
            );
        }

        if (end < pagination.totalPages - 1) {
            endButtons.push(
                <li key={-2} className="page-item px-3">
                    <span className="text-primary">...</span>
                </li>
            );
        }

        endButtons.push(
            <li key={pagination.totalPages} className={page === pagination.totalPages ? "page-item active" : "page-item"}>
                <button className="page-link shadow" onClick={() => handleClick(pagination.totalPages)}>{pagination.totalPages}</button>
            </li>
        );

        endButtons.push(
            <li key={pagination.totalPages + 1} className={page === pagination.totalPages ? "page-item disabled" : "page-item"}>
                <button className="page-link shadow" aria-label="Następna" onClick={() => handleClick(page + 1)}>
                    <span aria-hidden="true">&raquo;</span>
                </button>
            </li>
        );
    }

    const paginationComponent = (pagination && pagination.totalPages > 1 && (startButtons.length > 0 || paginationButtons.length > 0 || endButtons.length > 0)) &&
        (
            <nav aria-label="pagination" className="mb-5">
                <ul className="pagination justify-content-center">
                    {startButtons}
                    {paginationButtons}
                    {endButtons}
                </ul>
            </nav>
        );

    return (
        <>
            {paginationComponent}
        </>
    );
}

export default Pagination;