import { EventsContainer } from 'containers';
import React from 'react';

const EventsPage = () => {

    return (
        <>
            <div className="row mb-3">
                <h3>Wydarzenia</h3>
                <div className="m-2">
                    <a className="btn btn-outline-success" href="/events/new">
                        <i className="bi bi-plus-circle-fill me-1"></i>Dodaj
                    </a>
                </div>
            </div>
            <EventsContainer />
        </>
    );
}

export default EventsPage;