import { IOfferFormContainerProps } from "./OfferFormContainer.interface";
import { IEvent, IEventList, IOffer } from "interfaces";
import { useEffect, useState } from "react";
import OfferFormItem from "./subcomponents/OfferFormItem";
import axios from 'axios';
import { axiosHelper } from 'helpers';
import { notificationService } from 'services';

const OfferFormContainer = (props: IOfferFormContainerProps) => {

    const { id } = props;

    const [item, setItem] = useState<IOffer | null>(null);
    const [events, setEvents] = useState<IEvent[] | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => loadData(), []);

    const loadData = () => {

        setLoading(true);
        try {
            if (id !== null) handleFetchApi();
            handleFetchApiEvents();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleFetchApi = async () => {

        axios
            .get<IOffer>(
                axiosHelper.buildApiUrl(`offers/${id}`),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setItem(response.data);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Oferta nie istnieje";
                notificationService.error(errorMessage);
                setItem(null);
                setError(error);
            });
    }

    const handleFetchApiEvents = async () => {

        axios
            .get<IEventList>(
                axiosHelper.buildApiUrl('events'),
                axiosHelper.buildHeaders(null))
            .then(response => {
                setEvents(response.data.events);
            })
            .catch(error => {
                let errorMessage = "Nie można pobrać danych! Spróbuj ponownie później";
                if (error.response?.status === 400) errorMessage = "Wydarzenie nie istnieje";
                notificationService.error(errorMessage);
                setEvents(null);
                setError(error);
            });
    }

    return (
        <OfferFormItem item={item} loading={loading} error={error} events={events} newOffer={id === null} />
    );
}

export default OfferFormContainer;