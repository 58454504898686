import { IOfferFormItemProps } from "./OfferFormItem.interface";
import { FetchError, Loading } from "components/shared";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { notificationService } from "services";
import axios from 'axios';
import { axiosHelper } from 'helpers';

const OfferFormItem = (props: IOfferFormItemProps) => {

    const { item, error, loading, events, newOffer } = props;

    if (error) return <FetchError error={error} />;
    if (loading) return <Loading />;

    const [name, setName] = useState<string>("");
    const [skus, setSkus] = useState<string>("");
    const [eventId, setEventId] = useState<string>("");
    const [changed, setChanged] = useState<boolean>(false);

    let navigate = useNavigate();

    if (item !== null && !newOffer) {
        if (!changed) {
            if (name === "" && name != item.name) setName(item.name);
            if (skus === "" && skus != item.skus) setSkus(item.skus);
            if (eventId === "" && eventId != item.eventId) setEventId(item.eventId);
        }
    }

    if (item === null && newOffer) {
        if (eventId === "" && events != null) {
            const first = events.at(0);
            if (first && first.id !== null) setEventId(first.id);
        }
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setChanged(true);
    }

    const handleSkusChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

        let newValue = event.target.value;

        while (newValue.startsWith(',')) newValue = newValue.slice(1);
        newValue = newValue
            .replace(/ /g, ',')
            .replace(/\t/g, ',')
            .replace(/\r/g, ',')
            .replace(/\n/g, ',')
            .replace(/;/g, ',')
            .replace(/\|/g, ',');
        while (newValue.includes(',,')) newValue = newValue.replace(',,', ',');
        newValue = newValue.replace(/[^0-9,]/g, '');

        setSkus(newValue);
        setChanged(true);
    }

    const handleEventIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEventId(event.target.value);
        setChanged(true);
    }

    const handleSaveClick = () => {
        if (name.trim().length === 0 || skus.trim().length === 0 || eventId.trim().length === 0) {
            notificationService.error('Uzupełnij pola "Nazwa", "Wydarzenie", "Kody własne"');
            return;
        }

        if (item === null) addItem();
        else updateItem();
    }

    const addItem = () => {
        axios.post(
            axiosHelper.buildApiUrl(`offers`),
            axiosHelper.buildHeadersAndBody(null, { eventId, name, skus: removeCommaAtEnd(skus) }))
            .then(response => {
                if (response.status === 201) {
                    notificationService.success("Oferta została dodana!");
                    navigate('/offers');
                }
            })
            .catch(error => {
                console.error(error.response);
                notificationService.error("Dodawanie oferty zakończone niepowodzeniem");
            });
    }

    const updateItem = () => {
        axios.put(
            axiosHelper.buildApiUrl(`offers/${item?.id}`),
            axiosHelper.buildHeadersAndBody(null, { id: item?.id, eventId, name, skus: removeCommaAtEnd(skus), date: item?.date }))
            .then(response => {
                if (response.status === 204) {
                    notificationService.success("Oferta została zmienione!");
                    navigate('/offers');
                }
            })
            .catch(error => {
                console.error(error.response);
                notificationService.error("Modyfikacja oferty zakończone niepowodzeniem");
            });
    }

    const removeCommaAtEnd = (skus: string) => {
        if (skus.endsWith(',')) skus = skus.slice(0, -1);
        return skus;
    }

    const optionComponents = events?.filter(item => item.id !== null && item.name.trim().length > 0)
        .map(item => <option key={item.id} value={item.id === null ? undefined : item.id}>{item.name}</option>);

    return (
        <div className="form-group m-5">

            <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Nazwa</label>
                <input type="text" className="form-control" id="nameInput" placeholder="Nazwa" value={name} onChange={handleNameChange} />
            </div>

            <div className="mb-3">
                <label htmlFor="eventIdInput" className="form-label">Wydarzenie</label>
                <select className="form-select" id="eventIdInput" value={eventId} onChange={handleEventIdChange}>
                    <option value="" disabled selected hidden>Wybierz wydarzenie</option>
                    {optionComponents}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="skusTextarea" className="form-label">Kody własne (rozdzielone przecinkami)</label>
                <textarea className="form-control" id="skusTextarea" maxLength={3500} rows={18} value={skus} onChange={handleSkusChange}></textarea>
            </div>

            <div className="btn-group" role="group">
                <button className="btn btn-outline-success" onClick={handleSaveClick}>
                    <i className="bi bi-check-circle-fill me-1"></i>Zapisz
                </button>

                <a type="button" className="btn btn-outline-warning" href="/offers">
                    <i className="bi bi-x-circle-fill me-1"></i>Anuluj
                </a>
            </div>

        </div>
    );
}

export default OfferFormItem;