import { ProductsContainer } from "containers";
import { NotFoundPage } from "pages";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import './ShowroomPage.css';

const ShowroomPage = () => {

    const [paginationPage, setPaginationPage] = useState(1);
    const [searchParams] = useSearchParams();
    const offer: string | null = searchParams.get("offer");

    if (!offer || !offer.match(/^[A-Fa-f0-9]+$/)) {
        return <NotFoundPage />
    }

    const handlePaginationPageChange = (value: number) => {
        setPaginationPage(value);
    }

    return <ProductsContainer offer={offer} paginationPage={paginationPage} handlePaginationPageChange={handlePaginationPageChange} />;
}

export default ShowroomPage;