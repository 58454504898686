import { IEventFormItemProps } from "./EventFormItem.interface";
import { FetchError, Loading } from "components/shared";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { notificationService } from "services";
import axios from 'axios';
import { axiosHelper } from 'helpers';

const EventFormItem = (props: IEventFormItemProps) => {

    const { item, error, loading } = props;

    if (error) return <FetchError error={error} />;
    if (loading) return <Loading />;

    const [name, setName] = useState<string>(item !== null ? item.name : "");

    let navigate = useNavigate();

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleSaveClick = () => {
        if (name.trim().length === 0) {
            notificationService.error("Nazwa nie może być pusta");
            return;
        }

        if (item === null) addItem();
        else updateItem();
    }

    const addItem = () => {
        axios.post(
            axiosHelper.buildApiUrl(`events`),
            axiosHelper.buildHeadersAndBody(null, { name }))
            .then(response => {
                if (response.status === 201) {
                    notificationService.success("Wydarzenie zostało dodane!");
                    navigate('/events');
                }
            })
            .catch(error => {
                console.error(error.response);
                notificationService.error("Dodawanie wydarzenia zakończone niepowodzeniem");
            });
    }

    const updateItem = () => {
        axios.put(
            axiosHelper.buildApiUrl(`events/${item?.id}`),
            axiosHelper.buildHeadersAndBody(null, { id: item?.id, name, date: item?.date }))
            .then(response => {
                if (response.status === 204) {
                    notificationService.success("Wydarzenie zostało zmienione!");
                    navigate('/events');
                }
            })
            .catch(error => {
                console.error(error.response);
                notificationService.error("Modyfikacja wydarzenia zakończone niepowodzeniem");
            });
    }

    return (
        <div className="form-group m-5">

            <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Nazwa</label>
                <input type="text" className="form-control" id="nameInput" placeholder="Nazwa" value={name} onChange={handleNameChange} />
            </div>

            <div className="btn-group" role="group">
                <button className="btn btn-outline-success" onClick={handleSaveClick}>
                    <i className="bi bi-check-circle-fill me-1"></i>Zapisz
                </button>

                <a type="button" className="btn btn-outline-warning" href="/events">
                    <i className="bi bi-x-circle-fill me-1"></i>Anuluj
                </a>
            </div>

        </div>
    );
}

export default EventFormItem;