import { ISkuNameTypeProps } from "./SkuNameType.interface";
import './SkuNameType.css';

const SkuNameType = (props: ISkuNameTypeProps) => {

    const { sku, name, type, catalogName, dropshopingDescription } = props.item;

    return (
        <div className="align-self-center p-1">
            {catalogName && <div className="p-1">{catalogName}</div>}
            <div className="p-1">
                <div className="d-inline me-2 px-1 bg-secondary text-white rounded">{sku}</div>
                <div className="d-inline mx-2 fw-bold">{name}</div>
                <div className="d-inline ms-2">{type}</div>
            </div>
            {dropshopingDescription && <div className="p-1 sku-name-type-description">{dropshopingDescription}</div>}
        </div>
    );
}

export default SkuNameType;