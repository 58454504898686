import { EventFormContainer } from 'containers';
import { useParams } from 'react-router-dom';

const EventFormPage = () => {

    const { id } = useParams<string>();
    const title = id === "new" ? "Dodaj wydarzenie" : "Edycja wydarzenia";

    return (
        <>
            <div className="row mb-3">
                <h3>{title}</h3>
            </div>
            <EventFormContainer id={id !== undefined && id !== "new" ? id : null} />
        </>
    );
}

export default EventFormPage;