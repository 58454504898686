import { IImagesProps } from "./Images.interface";
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import './Images.css';

const Images = (props: IImagesProps) => {

    const { images } = props;

    const imagesForComponent: ReactImageGalleryItem[] = [];

    images?.forEach(image => {
        imagesForComponent.push({
            original: image.replace('PC_HURT-800/', 'PC_HURT-550/'),
            thumbnail: image.replace('PC_HURT-800/', 'PC_HURT-100/')
        });
    });

    if (!imagesForComponent || imagesForComponent.length == 0) {
        return (
            <div className="align-self-center p-2">
                <img
                    src="/images/noimage.png"
                    alt="[image not found]"
                    className="images-not-found" />
            </div>
        );
    }

    return (
        <div className="align-self-center p-2">
            <ImageGallery
                items={imagesForComponent}
                showFullscreenButton={true}
                showThumbnails={true}
                slideDuration={500}
                slideInterval={2500}
                lazyLoad={true}
                onErrorImageURL="/images/noimage.png"
            />
        </div>
    );
}

export default Images;