import { B2bButton, Barcode, SkuNameType } from 'components';
import { ImagesContainer } from 'containers';
import { IProductItemProps } from "./ProductItem.interface";

export const ProductItem = (props: IProductItemProps) => {

    const { item } = props;

    return (
        <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4" key={item.sku}>
            <div className="d-flex flex-column card shadow mb-4 p-3">
                <ImagesContainer item={item} />
                <SkuNameType item={item} />
                <Barcode item={item} />
                <B2bButton skus={item.sku.toString()} title="Otwórz w B2B" />
            </div>
        </div>
    );
};