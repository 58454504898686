import { IBarcodeProps } from "./Barcode.interface";
import { useBarcode } from 'next-barcode';

const Barcode = (props: IBarcodeProps) => {

    const { ean } = props.item;

    const { inputRef } = useBarcode({
        value: ean,
        options: {
            format: "EAN13",
            width: 2.2,
            height: 50,
            displayValue: true,
            font: "roboto",
            fontSize: 15,
            margin: 0,
            marginTop: 2,
            marginBottom: 3
        }
    });

    return (
        <div className="align-self-center p-2">
            <svg ref={inputRef} />
        </div>
    );
}

export default Barcode;