import { AxiosRequestConfig } from "axios";

class AxiosHelper {

    buildApiUrl = (controller: string): string => {

        return process.env.NODE_ENV !== 'production'
            ? `https://localhost:7185/api/${controller}`
            : `/api/${controller}`;
    }

    buildHeaders = (params: any | null): AxiosRequestConfig => {

        let config = {
            headers: {
                ContentType: 'application/json; charset=utf-8',
                Accept: 'application/json'
            }
        };

        if (params) {
            config = Object.assign(config, params);
        }

        return config;
    }

    buildHeadersAndBody = (params: any | null, data: any | null): AxiosRequestConfig => {

        let config = this.buildHeaders(params);

        if (data) {
            config = Object.assign(config, data);
        }

        return config;
    }
}

const axiosHelper = new AxiosHelper();
export default axiosHelper;
