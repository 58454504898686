import './NoResults.css';

const NoResults = () => (

    <div className="text-center">
        <i className="bi bi-exclamation-circle text-warning no-results-icon"></i>
        <h3 className="mt-2 text-secondary">Brak danych ...</h3>
    </div>
);

export default NoResults;