import './NotFoundPage.css';

const NotFoundPage = () => {
    return (
        <div className="text-center">
            <i className="bi bi-exclamation-octagon text-danger not-found-page-icon"></i>
            <h3 className="mt-2 text-secondary">Strona nie istnieje!</h3>
        </div >
    );
}

export default NotFoundPage;