import { IEventListProps } from "./IEventList.interface";
import { FetchError, Loading, NoResults } from "components/shared";
import EventItem from "./EventItem";

const EventList = (props: IEventListProps) => {

    const { items, error, loading, reloadEvents } = props;

    if (error) return <FetchError error={error} />;
    if (loading || !items) return <Loading />;
    if (items.length === 0) return <NoResults />;

    const eventsComponents = items?.map(item => <EventItem event={item} reloadEvents={reloadEvents} />);

    return (
        <div className="row">
            {eventsComponents}
        </div>
    );
}

export default EventList;