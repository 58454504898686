import './Header.css';

const Header = () => {
    return (
        <header className="fixed-top mb-4">
            <div className="bg-dark text-white-50 p-2">
                <div className="d-flex flex-row justify-content-start">
                    <img className="align-self-center header-eurofirany-logo mx-5" src="/images/eurofirany-logo.png" alt="[eurofirany]" />
                    <h2 className="align-self-center m-0">Showroom</h2>
                </div>
            </div>
        </header >
    );
}

export default Header;